import { Box, Flex, Heading } from "@chakra-ui/react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"

const ArticleCard = (props) => {
  const { title, slug, created, lastModified, ogpImage } =
    props

  return (
    <Link to={`/${slug}`} itemProp="url" my="0.5em">
      <article
        className="post-list-item"
        itemScope
        itemType="http://schema.org/Article"
      >
        <Flex py={0} align="center" _hover={{ opacity: 0.7 }}>
          {ogpImage && (
            <Box my={4} mr="1em">
              <GatsbyImage image={ogpImage} alt="画像" />
            </Box>
          )}
          <Box display="block">
            <Heading
              fontSize="1rem"
              as="h3"
              my={2}
              //   wordBreak="break-all"
              overflowWrap={{ base: "break-word", md: "normal" }}
            >
              {/* /を入れないと、このPostCardコンポーネントを"/"直下以外で使った場合に、そこからの相対パスが宛先に指定されてしまう。*/}
              {/* 例： Link to={slug}として、PostCardを/tags/React直下で使った場合・・・tags/React/react-aabbbbにルーティングされる。*/}

              <span itemProp="headline">{title}</span>
            </Heading>
            <Box color="gray.500" fontSize="xs">
              {lastModified
                ? `${created} （${lastModified} 最終更新）`
                : created}
            </Box>
            {/* <ContentsDate created={created} modified={lastModified} /> */}
            {/* <section>
            <p
              dangerouslySetInnerHTML={{
                __html: description,
              }}
              itemProp="description"
            />
          </section> */}
          </Box>
        </Flex>
      </article>
    </Link>
  )
}

export default ArticleCard
