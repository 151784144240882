import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import HatenaArticles from "../components/organisms/hatena-articles"
import Profile from "../components/organisms/profile"
import TagsList from "../components/organisms/tags-list"
import ArticleCard from "../components/molecules/article-card"
import HomeMainHeader from "../components/atom/home-main-header"

const AllPosts = ({ location }) => {
  const sideBarContents = [<Profile />, <HatenaArticles />, <TagsList />]

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
      allMdx(
        filter: { fileAbsolutePath: { regex: "/myblog/" } }
        sort: { fields: frontmatter___created, order: DESC }
      ) {
        nodes {
          frontmatter {
            slug
            title
            modified_date(formatString: "YYYY/M/DD")
            created(formatString: "YYYY/M/DD")
            tags
            description
            thumbnail_image {
              childImageSharp {
                gatsbyImageData(width: 150, layout: CONSTRAINED)
              }
            }
          }
          parent {
            ... on File {
              sourceInstanceName
            }
          }
        }
      }
    }
  `)
  const posts = data.allMdx.nodes

  return (
    <Layout location={location} sideBarContents={sideBarContents}>
      <HomeMainHeader>記事一覧</HomeMainHeader>
      <ol style={{ listStyle: `none` }}>
        {posts.map((post) => {
          const title = post.frontmatter.title || "タイトルなし"
          const slug = post.frontmatter.slug
          const created = post.frontmatter.created
          const lastModified =
            post.frontmatter.modified_date[0] || post.frontmatter.created
          const description = post.frontmatter.description || post.excerpt
          const tags = [post.frontmatter.tags[0]] || [""]
          const ogpImage =
            post.frontmatter.thumbnail_image &&
            post.frontmatter.thumbnail_image.childImageSharp.gatsbyImageData
          return (
            <ArticleCard
              title={title}
              slug={slug}
              created={created}
              lastModified={lastModified}
              description={description}
              tags={tags}
              ogpImage={ogpImage}
            />
          )
        })}
      </ol>
    </Layout>
  )
}

export default AllPosts
