import { Heading } from "@chakra-ui/react"
import * as React from "react"
const HomeMainHeader = ({ children, as }) => {
  return (
    <Heading
      as={as}
      size="lg"
      fontSize="3xl"
      fontWeight="normal"
      color="orange.900"
      borderBottom="2px solid"
      my={5}
      py={2}
    >
      {children}
    </Heading>
  )
}

export default HomeMainHeader
